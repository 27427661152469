import { linkedin, github, gmail } from '../../assets/icons/svgIconsMap';



const footerData = {
  title: 'Find me on these platforms',
  copyRights: 'Made with love by Karin - Ben Zaken / 2023',
  socialLinks: [
    { name: 'LinkedIn', url: 'https://www.linkedin.com/in/karin-ben-zaken-848b061b4', icon: linkedin },
    { name: 'GitHub', url: 'https://github.com/karin167', icon: github },
    { name: 'Gmail', url: 'mailto: karin167bz@gmail.com', icon: gmail },

  ],
};
export default footerData;
