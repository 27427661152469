export const headerData = {
  title: 'Experiences',
  description: 'List of my recent jobs and programing related experiences',
};

const timelineItems = [
  {
    time: 'July-2023-Until now ',
    title: 'Software developer Engineer - Mid-Level',
    description: 'working within cross-functional team on:',
    company: {
      name: 'CyberDesk',
      link: 'https://www.cyberdesk.app/',
    },
    location: 'Germany',
    workList: ['Take care of a new projects/System', 'Write a technical specs', 'working with: Remix js, Tailwind CSS, TypeScript, JavaScript, React.js, Figma, Tailwindcss, Node.js', 'to create a generic components', 'design the platform- working with Figma', 'Take care of test and release to production', 'Work in collaboration with the team'],
    references: false,
    jobDescription: 'CyberDesk website still on building progress',
  },
  {
    time: 'January 2022 - July-2023 ',
    title: 'Software developer Engineer',
    description: 'working within cross-functional team on:',
    company: {
      name: 'AUTO1 GROUP SE',
      link: 'https://www.auto1.com/de/home',
    },
    location: 'Germany',
    workList: ['To migrate old front-end development system to modern one', 'To take care of new features & Projects', 'Write a technical specs', 'to take care of release', 'To work in collaboration with the team'],
    references: false,
    jobDescription: 'See AUTO1 website:',
  },
  {
    time: '2020-2021',
    title: 'Web Development diploma in DCI',
    description: 'Full Stack Developer:',
    company: {
      name: 'DCI - Digital Career Institute',
      link: 'https://digitalcareerinstitute.org/',
    },
    location: 'Germany',
    workList: ['JavaScript (ES6)', 'MongoDB', 'Express', 'React','NodeJS', 'HTML & CSS','Bootstrap & React Bootstrap'],
    references: false,
    jobDescription: 'See DCI website:',
  },
  {
    time: 'Oct 2019 - Oct 2020',
    title: 'Customer service',
    description: 'As a customer service:',
    workList: ['I assisted with technical problems',
      'I ́ve got vast experience speaking with customers'],
    company: {
      name: 'PayPal',
      link: 'https://www.paypal.com/de/home',
    },
    location: 'Germany',
    jobDescription: 'See PayPal website:',
  },
  {
    time: '2016 - 2017',
    title: 'Marketing Manager ',
    description: 'Managing and marketing the company ́s website and Facebook page:',
    company: {
      name: 'Mona Tours',
      link: 'https://www.monatours.co.il/',
    },
    location: 'Israel',
    workList: ['Monitoring the company ́s marketing data',
      'Selling the company ́s products and meeting the company ́s sales objectives'],
    references: false,
    jobDescription: 'See Mona Tours website:',
  },
];

export default timelineItems;
