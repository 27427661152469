import {
  frontend, testing, teamwork, backend, tools,
} from '../../../assets/icons/svgIconsMap';
import js from '../../../assets/icons/png/javascript.png';
import react from '../../../assets/icons/png/react.png';
import nodeJs from '../../../assets/icons/png/nodeJs.png';
import ts from '../../../assets/icons/png/ts.png';
import jest from '../../../assets/icons/png/jest.png';
import testinglibrary from '../../../assets/icons/png/testinglibrary.png';
import mongodb from '../../../assets/icons/png/mongodb.png';
import git from '../../../assets/icons/png/git.png';
import vscode from '../../../assets/icons/png/vscode.png';
import api from '../../../assets/icons/png/api.png';
import atlassian from '../../../assets/icons/png/atlassian.png';
import sketch from '../../../assets/icons/png/sketch.png';

export const headerData = {
  title: 'Skills and TechStack',
  description: 'My experience skills:',
};

const contentData = [
  {
    title: 'Front End',
    icon: frontend,
    listItems: [
      {
        title: 'JavaScript',
        subTitle: 'ES6-ES7/ TypeScript',
        icon: js,
      },
      {
        title: 'React',
        subTitle: 'hooks/ Best Practices, Remix.Js',
        icon: react,
      },
      {
        title: 'TaypeScript',
        subTitle: '',
        icon: ts,
      },
    ],
  },
  {
    title: 'Testing',
    icon: testing,
    listItems: [
      {
        title: 'Jest',
        subTitle: 'for React apps',
        icon: jest,
      },
      {
        title: 'Testing Library',
        subTitle: 'functional/integration',
        icon: testinglibrary,
      },

    ],
  },
  {
    title: 'BE/ Databases',
    icon: backend,
    listItems: [
      {
        title: 'Node.JS',
        subTitle: '',
        icon: nodeJs,
      },
      {
        title: 'MongoDB',
        subTitle: 'Non SQL DB',
        icon: mongodb,
      },

    ],
  },
  {
    title: 'Developer tools',
    icon: tools,
    listItems: [
      {
        title: 'Git',
        subTitle: 'versioning control, github. bitBucket',
        icon: git,
      },
      {
        title: 'Network',
        subTitle: 'RESTful API',
        icon: api,
      },
      {
        title: 'Modern Editors',
        subTitle: 'code pairing/ remote working',
        icon: vscode,
      },
    ],
  },
  {
    title: 'Team work',
    icon: teamwork,
    listItems: [
      {
        title: 'JIRA/ Confluence',
        subTitle: 'Project managing',
        icon: atlassian,
      },
      {
        title: 'Design/ wireframming',
        subTitle: 'Gimp, figma',
        icon: sketch,
      },
    ],
  },
];

export default contentData;
